<template>
  <div class="gallery">
    <img :src="currentUrl" />
    <nav-buttons :clickHandler="setScreen" v-if="currentIndex != 0" />
    <button
      class="calibrationButton"
      @click="setScreen('calibration')"
      v-if="currentIndex == 2"
    ></button>
    <button
      class="calibrationBackButton"
      @click="setScreen('instructions')"
      v-if="currentIndex == 0"
    ></button>
    <button
      class="homeStartButton"
      @click="setScreen('instructions')"
      v-if="currentIndex == 1"
    ></button>
  </div>
</template>

<script>
import homeScreenUrl from "@/assets/screens/home.jpg";
import instructionsScreenUrl from "@/assets/screens/instructions.jpg";
import calibrationScreenUrl from "@/assets/screens/calibration.jpg";
import newsScreenUrl from "@/assets/screens/news.jpg";
import amazonScreenUrl from "@/assets/screens/amazon.jpg";
import smartphonesScreenUrl from "@/assets/screens/smartphones.jpg";
import mailScreenUrl from "@/assets/screens/mail.jpg";
import armasuissScreenUrl from "@/assets/screens/armasuisse.jpg";
import gimmicksUrl from "@/assets/screens/gimmicks.jpg";
import NavButtons from "../components/NavButtons.vue";
const gallery = [
  calibrationScreenUrl,
  homeScreenUrl,
  instructionsScreenUrl,
  amazonScreenUrl,
  newsScreenUrl,
  smartphonesScreenUrl,
  armasuissScreenUrl,
  mailScreenUrl,
  gimmicksUrl,
];
let currentIndex = 1;
let currentUrl = gallery[currentIndex];
export default {
  name: "Login",
  components: {
    NavButtons,
  },
  data() {
    return {
      currentUrl,
      currentIndex,
    };
  },
  mounted: function () {
    this.addHandler();
  },
  destroyed: function () {
    this.removeHandler();
  },
  methods: {
    test(a) {
      console.log(currentIndex);
      console.log(a);
      return true;
    },
    addHandler: function () {
      window.addEventListener("keydown", this.keyEventListener);
    },
    removeHandler: function () {
      window.removeEventListener("keydown", this.keyEventListener);
    },
    keyEventListener: function (event) {
      if (
        event.key == "PageDown" ||
        event.key == "ArrowDown" ||
        event.key == "ArrowRight"
      ) {
        if (this.currentIndex == 0) {
          this.setScreen("instructions");
        } else {
          this.setScreen("next");
        }
      } else if (
        event.key == "PageUp" ||
        event.key == "ArrowUp" ||
        event.key == "ArrowLeft"
      ) {
        if (this.currentIndex == 0) {
          this.setScreen("instructions");
        } else {
          this.setScreen("prev");
        }
      }
    },
    setScreen(screen) {
      if (screen == "next" || screen == "prev") {
        if (screen == "next") {
          this.currentIndex++;
        } else {
          this.currentIndex--;
        }
        if (this.currentIndex <= 0) this.currentIndex = gallery.length - 1;
        else if (this.currentIndex >= gallery.length) this.currentIndex = 1;
      } else if (screen == "calibration") {
        this.currentIndex = 0;
      } else if (screen == "home") {
        this.currentIndex = 1;
      } else if (screen == "instructions") {
        this.currentIndex = 2;
      } else if (screen == "amazon") {
        this.currentIndex = 3;
      } else if (screen == "news") {
        this.currentIndex = 4;
      } else if (screen == "smartphones") {
        this.currentIndex = 5;
      } else if (screen == "armasuisse") {
        this.currentIndex = 6;
      } else if (screen == "mail") {
        this.currentIndex = 7;
      } else if (screen == "gimmicks") {
        this.currentIndex = 8;
      }
      this.currentUrl = gallery[this.currentIndex];
    },
  },
};
</script>

<style lang="sass" scoped>
.gallery
  position: relative
  display: inline-block

img
  display: block
  margin: auto
  max-width: 100vw
  max-height: 99.5vh

.calibrationButton
  position: absolute
  width: 5%
  height: calc(5% * 1.779)
  background-color: transparent
  border-color: transparent
  border-radius: 20px
  border: none
  top: 5.7%
  left: 3.7%

.calibrationBackButton
  position: absolute
  width: 16.5%
  height: calc(5.3% * 1.779)
  bottom: 5.8%
  transform: translate(-50%)
  background-color: transparent
  border-color: transparent
  border-radius: 15px
  border: none

.homeStartButton
  position: absolute
  width: 21.5%
  height: calc(4.8% * 1.779)
  left: 48.5%
  bottom: 26%
  transform: translate(-50%)
  background-color: transparent
  border-color: transparent
  border-radius: 15px
  border: none
</style>
