<template>
  <div class="navButtons">
    <button class="homeButton" @click="clickHandler('home')"></button>
    <button
      class="instructionsButton"
      @click="clickHandler('instructions')"
    ></button>
    <button class="screenButtonOne" @click="clickHandler('amazon')"></button>
    <button class="screenButtonTwo" @click="clickHandler('news')"></button>
    <button
      class="screenButtonThree"
      @click="clickHandler('smartphones')"
    ></button>
    <button
      class="screenButtonFour"
      @click="clickHandler('armasuisse')"
    ></button>
    <button class="screenButtonFive" @click="clickHandler('mail')"></button>
    <button class="screenButtonSix" @click="clickHandler('gimmicks')"></button>
    <button class="buttonLeft" @click="clickHandler('prev')"></button>
    <button class="buttonRight" @click="clickHandler('next')"></button>
  </div>
</template>

<script>
export default {
  name: "NavButtons",
  props: {
    clickHandler: { type: Function },
  },
  data() {
    return {};
  },
};
</script>

<style lang="sass" scoped>
$buttonBottom: 5.8%
$buttonSpacing: 0.63%
$firstButtonLeft: 6%
$buttonSize: 3.2%

button
  position: absolute
  width: $buttonSize
  height: calc($buttonSize * 1.779)
  background-color: transparent
  border: none

.homeButton
  bottom: $buttonBottom
  left: $firstButtonLeft
  transform: translate(-50%, 50%)

.instructionsButton
  bottom: $buttonBottom
  left: $firstButtonLeft + $buttonSpacing + $buttonSize
  transform: translate(-50%, 50%)

.screenButtonOne
  bottom: $buttonBottom
  left: $firstButtonLeft + ($buttonSpacing + $buttonSize) * 2
  transform: translate(-50%, 50%)

.screenButtonTwo
  bottom: $buttonBottom
  left: $firstButtonLeft + ($buttonSpacing + $buttonSize) * 3
  transform: translate(-50%, 50%)

.screenButtonThree
  bottom: $buttonBottom
  left: $firstButtonLeft + ($buttonSpacing + $buttonSize) * 4
  transform: translate(-50%, 50%)

.screenButtonFour
  bottom: $buttonBottom
  left: $firstButtonLeft + ($buttonSpacing + $buttonSize) * 5
  transform: translate(-50%, 50%)

.screenButtonFive
  bottom: $buttonBottom
  left: $firstButtonLeft + ($buttonSpacing + $buttonSize) * 6
  transform: translate(-50%, 50%)

.screenButtonSix
  bottom: $buttonBottom
  left: $firstButtonLeft + ($buttonSpacing + $buttonSize) * 7
  transform: translate(-50%, 50%)

$arrowButtonBottom: 6.2%
$arrowButtonSpacing: 0.63%
$firstArrowButtonLeft: 48.15%

.buttonLeft
  bottom: $buttonBottom
  left: $firstArrowButtonLeft
  transform: translate(-50%, 50%)

.buttonRight
  bottom: $buttonBottom
  left: $firstArrowButtonLeft + $buttonSpacing + $buttonSize
  transform: translate(-50%, 50%)
</style>
